import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'leads',
      category: 'leads',
      default: true,
      defaultRoute: '/leads/overview',
      displayName: 'Leads',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: undefined,
      children: [
        {
          parent: '/leads',
          route: 'overview',
          displayName: 'Overview',
          iconClass: 'fa fa-users',
          pdfEnabled: true,
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'sourcePerformance',
          displayName: 'Source',
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'vehiclePerformance',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'eventPerformance',
          displayName: 'Event',
          iconClass: 'fa fa-car',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'facebook-performance',
          displayName: 'Facebook Leads',
          iconClass: 'fa fa-facebook-square',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'transactions',
          displayName: 'Transactions',
          iconClass: 'fa fa-exchange',
          roles: undefined
        },
      ]
    },
    //{
    //  id: 'website',
    //  category: 'website',
    //  defaultRoute: '/website/overview',
    //  displayName: 'Website',
    //  displayInNav: true,
    //  iconClass: 'fa fa-globe',
    //  roles: undefined,
    //  children: [
    //    {
    //      parent: '/website',
    //      route: 'overview',
    //      displayName: 'Overview',
    //      pdfEnabled: true,
    //      iconClass: 'fa fa-users',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'scorecard',
    //      displayName: 'Scorecard',
    //      iconClass: 'fa fa-list',
    //      roles: ['System Administrator', 'Corporate', 'Region Manager', 'District Manager']
    //    },
    //    {
    //      parent: '/website',
    //      route: 'summary',
    //      displayName: 'Summary',
    //      iconClass: 'fa fa-list',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'referrer',
    //      displayName: 'Referrer',
    //      iconClass: 'fa fa-share',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'vehicle',
    //      displayName: 'Vehicle',
    //      iconClass: 'fa fa-car',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'provider',
    //      displayName: 'Provider',
    //      iconClass: 'fa fa-cogs',
    //      roles: ['System Administrator', 'Corporate']
    //    },
    //  ]
    //},
    //{
    //  id: 'inventory',
    //  category: 'inventory',
    //  defaultRoute: '/inventory/overview',
    //  displayName: 'Inventory',
    //  displayInNav: true,
    //  iconClass: 'fa fa-car',
    //  roles: ['System Administrator', 'Corporate', 'Region Manager', 'Area Manager', 'District Manager'],
    //  children: null,
    //},
    //{
    //  id: 'sales',
    //  category: 'sales',
    //  defaultRoute: '/sales/overview',
    //  displayName: 'Sales',
    //  displayInNav: true,
    //  iconClass: 'fa fa-usd',
    //  roles: undefined,
    //  children: [
    //    {
    //      parent: '/sales',
    //      route: 'overview',
    //      displayName: 'Overview',
    //      pdfEnabled: true,
    //      iconClass: 'fa fa-users',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/sales',
    //      route: 'summary',
    //      displayName: 'Summary',
    //      iconClass: 'fa fa-list',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/sales',
    //      route: 'vehicle',
    //      displayName: 'Vehicle',
    //      iconClass: 'fa fa-car',
    //      roles: undefined
    //    },
    //  ]
    //},
    //{
    //  id: 'provider-tools',
    //  category: 'provider-tools',
    //  default: true,
    //  defaultRoute: '/provider-tools/digital-retailing',
    //  displayName: 'Provider Tools',
    //  displayInNav: true,
    //  iconClass: 'fa fa-building',
    //  roles: undefined,
    //  children: [{
    //    parent: '/provider-tools',
    //    route: 'digital-retailing',
    //    displayName: 'Digital Retailing (BETA)',
    //    pdfEnabled: false,
    //    breadcrumbHelpEnabled: true,
    //    iconClass: 'fa fa-users',
    //    roles: undefined
    //  }, {
    //    parent: '/provider-tools',
    //    route: 'chat-overview',
    //    displayName: 'Chat Overview',
    //    pdfEnabled: false,
    //    breadcrumbHelpEnabled: true,
    //    iconClass: 'fa fa-users',
    //    roles: undefined
    //  }]
    //},
    //{
    //  id: 'dealerResources',
    //  category: 'dealerResources',
    //  defaultRoute: '/dealerResources/marketingBestPractices',
    //  displayName: 'Dealer & Field Resources',
    //  displayInNav: true,
    //  iconClass: 'fa fa-wrench',
    //  roles: undefined,
    //  children: [
    //    {
    //      parent: '/dealerResources',
    //      route: 'marketingBestPractices',
    //      displayName: 'Marketing Best Practices',
    //      iconClass: '',
    //      roles: undefined,
    //    },
    //    {
    //      parent: '/dealerResources',
    //      route: 'leadBestPractices',
    //      displayName: 'Lead Best Practices',
    //      iconClass: '',
    //      roles: undefined,
    //    },
    //    {
    //      parent: '/dealerResources',
    //      route: 'dealerBestPractices',
    //      displayName: 'Dealer Best Practices',
    //      iconClass: '',
    //      roles: undefined,
    //    },
    //    {
    //      parent: '/dealerResources',
    //      route: 'dealerWebsiteBestPractices',
    //      displayName: 'Dealer Website Page Best Practices',
    //      iconClass: '',
    //      roles: undefined,
    //    }
    //  ]
    //},
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator'],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator']
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator']
        },
        {
          parent: '/management',
          route: 'reportingModels',
          displayName: 'Reporting Models',
          iconClass: 'fa fa-users',
          roles: ['System Administrator']
        },
        {
          parent: '/management',
          route: 'salesCalendar',
          displayName: 'Sales Calendar',
          iconClass: 'fa fa-users',
          roles: ['System Administrator']
        },
        {
          parent: '/management',
          route: 'salesTargets',
          displayName: 'Sales Targets',
          iconClass: 'fa fa-users',
          roles: ['System Administrator']
        },
      ]
    },
  ];
}
