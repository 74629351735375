import { Component } from '@angular/core';
import { ConfigurationService } from '../_shared/services/services-index';

@Component({
  selector: 'layout-footer',
  templateUrl: './footer.component.html'
})
export class LayoutFooterComponent {
  clientName: string;
  currentYear: number;

  constructor(private configService: ConfigurationService) {
    this.clientName = this.configService.defaultAppSettings.clientName;
    this.currentYear = new Date().getFullYear();
  }
}
