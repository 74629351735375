import {
    IDefaultTableColumnConfig,
    ISalesSummaryConfig,
    IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';
export class SalesSummaryConfig implements ISalesSummaryConfig {

    public metricDisplayModes = [
      { name: 'MOM', value: 'MOM' },
      { name: 'YOY', value: 'YOY' }
  ];

  public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.saleMetrics.totalSales.metricId,
                displayName: Enums.saleMetrics.totalSales.name,
                propertyName: Enums.saleMetrics.totalSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.leadSales.metricId,
                displayName: Enums.saleMetrics.leadSales.name,
                propertyName: Enums.saleMetrics.leadSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.leadContribution.metricId,
                displayName: Enums.saleMetrics.leadContribution.name,
                propertyName: Enums.saleMetrics.leadContribution.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.saleMetrics.averageTimeToSale.metricId,
                displayName: Enums.saleMetrics.averageTimeToSale.name,
                propertyName: Enums.saleMetrics.averageTimeToSale.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
        ],

        defaultMetricOneId: Enums.saleMetrics.totalSales.metricId,
        defaultMetricTwoId: Enums.saleMetrics.leadSales.metricId,
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    };
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
      { show: true,
        header: '',
        columnDef: 'entity',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.entityDisplayName,
        clickable: true
      },
      {
        show: false,
        print: true,
        header: 'Dealer Name',
        columnDef: 'displayName',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
      },
      {
        show: true, header: 'Total Sales',
        columnDef: 'totalSales',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: false,
        header: 'Prev. Month Total Sales',
        columnDef: 'totalSalesPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: false,
        header: 'Total Sales MOM',
        columnDef: 'totalSalesMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Year Total Sales',
        columnDef: 'totalSalesPreviousYear',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: false,
        header: 'Total Sales YOY',
        columnDef: 'totalSalesYOY',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Sales From Leads',
        columnDef: 'leadSales',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: false,
        header: 'Prev. Month Sales From Leads',
        columnDef: 'leadSalesPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: false,
        header: 'Total Sales From Leads MOM',
        columnDef: 'leadSalesMOM',
        isMom: true,
        isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Year Sales From Leads',
        columnDef: 'leadSalesPreviousYear',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
       },
      {
        show: false,
        header: 'Sales From Leads YOY',
        columnDef: 'leadSalesYOY',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Lead Contr. Rate',
        columnDef: 'leadContribution',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Month Lead Contr. Rate',
        columnDef: 'leadContributionPreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Lead Contr. Rate MOM',
        columnDef: 'leadContributionMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Year Lead Contr. Rate',
        columnDef: 'leadContributionPreviousYear',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Lead Contr. Rate YOY',
        columnDef: 'leadContributionYOY',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Avg. Time to Sale',
        columnDef: 'averageTimeToSale',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.integerDaysOrNa, clickable: false
      },
      {
        show: false,
        header: 'Prev. Month Avg. Time to Sale',
        columnDef: 'averageTimeToSalePreviousMTD',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.integerDaysOrNa, clickable: false
      },
      {
        show: false,
        header: 'Avg. Time to Sale MOM',
        columnDef: 'averageTimeToSaleMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Year Avg. Time to Sale',
        columnDef: 'averageTimeToSalePreviousYear',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.integerDaysOrNa, clickable: false
      },
      {
        show: false,
        header: 'Avg. Time to Sale YOY',
        columnDef: 'averageTimeToSaleYOY',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
  ];

}
