import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppInsightsService } from '../appInsights/appInsights.service';
import { environment } from '../../../../environments/environment';
import { ContentModel, ListRequestModel, ListResponseModel } from '../../models/models-index';
import { UtilsService } from '../utils/utils.service';
import { catchError } from 'rxjs/operators';
import { Content } from '@angular/compiler/src/render3/r3_ast';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  headers = {'Content-Type': 'application/json'};

  constructor(
      private http: HttpClient) { }

  private api = `${environment.baseApiUri}/content/`;

  getContent(name: string, locale?: string) {
    const localeSuffix = (!locale || locale === 'en') ? '' : '_' + locale;
    return this.http.get<ContentModel>(`${this.api}?name=${name + localeSuffix}`);
  }

  getList(requestModel: ListRequestModel) {
    return this.http.post<ListResponseModel<ContentModel>>(
      `${this.api}/list`,
      requestModel,
      { headers: this.headers });
  }

  createContent(content: ContentModel) {
    return this.http.post<ContentModel>(
      this.api,
      content,
      { headers: this.headers }
    );
  }

  updateContent(content: ContentModel) {
    return this.http.put<ContentModel>(
      this.api + content.id,
      content,
      { headers: this.headers }
    );
  }

  deleteContent(id: number) {
    return this.http.delete(this.api + id);
  }
}
