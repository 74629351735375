import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as AppStates from '../../_store/app-state.model';
import { AppSelectors } from '../../_store/selector-types';
import { Enums } from '../enums/enums';
import * as Models from '../models/models-index';
import { map, delay, tap } from 'rxjs/operators';
import { FilterService } from '../services/services-index';
import { FilterSelectors } from '../filter/store';


@Component({
  selector: 'app-print-header',
  templateUrl: './printHeader.component.html'
})
export class PrintHeaderComponent implements OnInit, OnDestroy {
  printBreadcrumb: string;
  reportName$:  Observable<string>;
  private filterReportName: string;
  private orgLookups: Models.IOrgLookup[];
  private filterModel: Models.IFilterModel;
  private subscriptions: Subscription[] = [];

  private enums = Enums;
  public brandLogo = '/assets/images/print_logo.png';

  constructor(
    private store: Store<AppStates.AppState>,
    private filterService: FilterService

  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(AppSelectors.selectOrgLookups).subscribe(orgLookups =>
        this.orgLookups = orgLookups
      ),
      this.store.select(AppSelectors.selectCurrentRouteData).pipe(
        tap(routeData => this.filterReportName = routeData.reportName)
      ).subscribe(),
      this.filterService.filter$.pipe(
        tap(filterModel => this.filterModel = filterModel),
      ).subscribe(),
    );

    this.reportName$ = this.filterService.activePageTitle$.pipe(delay(0));
    this.initializePrintBreadCrumbs();

  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  initializePrintBreadCrumbs(): void {
    const reportDate = new Date(this.filterModel.startDate);
    let orgType: string; // website overview needs org type
    let orgName: string;
    const dateString: string = reportDate.toLocaleString('default', { month: 'long'}) + ', ' + reportDate.getFullYear().toString();
    // jmstodo: come back and simplify
    let breadcrumbString = '';

    if (this.filterModel.dealerCode) {
        const org = this.orgLookups.find(ol => ol.dealerCode === this.filterModel.dealerCode);
        orgName = org.dealerName;
        orgType = this.enums.hierarchicalEntityTypes.dealer.displayName;
    } else if (this.filterModel.orgCode1) {
        const org = this.orgLookups.find(ol => ol.orgCode1 === this.filterModel.orgCode1);
        orgName = this.filterModel.orgCode1;
        orgType = this.enums.hierarchicalEntityTypes.orgcode1.displayName;
    } else if (this.filterModel.orgCode2) {
        const org = this.orgLookups.find(ol => ol.orgCode2 === this.filterModel.orgCode2);
        orgName = org.orgCode2Name;
        orgType = this.enums.hierarchicalEntityTypes.orgcode2.displayName;
    } else if (this.filterModel.orgCode3) {
      const org = this.orgLookups.find(ol => ol.orgCode3 === this.filterModel.orgCode3);
      orgName = org.orgCode3Name;
      orgType = this.enums.hierarchicalEntityTypes.orgcode3.displayName;
    } else {
        orgName = orgType =  this.enums.hierarchicalEntityTypes.national.displayName;
    }
    if (orgType.toLowerCase() !== 'national') {
        breadcrumbString += ((this.filterReportName.includes('website') || this.filterReportName.includes('sales')) ? (orgType + ' > ') : '');
    }
    breadcrumbString += orgName + ': ' + dateString;

    this.printBreadcrumb = breadcrumbString;
  }
}
