import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../_shared/services/authentication/authentication.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { LAYOUT_ANIMATIONS } from './layout.animations';
import { ConfigurationService, PrintService } from '../_shared/services/services-index';
import { Enums, IEnums } from '../_shared/enums/enums';
import { LayoutHelperService } from './layoutHelper.service';
import { Router, NavigationStart } from '@angular/router';
import * as Models from '../_shared/models/models-index';
import { Store, select } from '@ngrx/store';
import { States } from '../_store/app.reducer';
import { selectLoggedInUser } from '../_store/app.selectors';
import { AppSelectors } from '../_store/selector-types';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  animations: LAYOUT_ANIMATIONS
})
export class LayoutComponent implements OnInit, OnDestroy {

  isPrintMode$ = this.printService.isPrintMode$;
  public loading$: Observable<boolean>;
  subscriptions: Subscription[] = [];

  secondaryNavState = 'open';
  defaultSidebarState = !!environment.sidebarDefaultOpen ? 'closed' : 'open'; // this is reversed intentionally so it works right
  enums: IEnums = Enums;
  authInfo: Models.IAuthenticationInfo;
  authInfo$ = this.store$.pipe(select(selectLoggedInUser));
  currentRouteData$ = this.store$.pipe(select(AppSelectors.selectCurrentRouteData));
  showFilterBar$: Observable<boolean>;
  showBreadcrumb$ = combineLatest([this.currentRouteData$, this.isPrintMode$]).pipe(
    map(([routeData, isPrintMode]) => !routeData.hideBreadcrumbs && !isPrintMode)
  );


  constructor(
    private store$: Store<States.AppState>,
    private configService: ConfigurationService,
    public layoutHelper: LayoutHelperService,
    private printService: PrintService,
    private router: Router) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.layoutHelper.toggleSidebar(this.defaultSidebarState);
        }
      }),
      this.authInfo$.subscribe(authInfo => this.authInfo = authInfo)
    );
    this.layoutHelper.toggleSidebar(this.defaultSidebarState);
    this.layoutHelper.updateCurrentCategory();

    this.showFilterBar$ = this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
      map(routeData => {
        const config = this.configService.filter.filterConfig.filterReportConfigs[routeData.reportName];
        return ((config?.filters || []).length > 0) && (routeData.hideFilters != true);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
