import { IDefaultTableColumnConfig, ILeadsVehicleConfig, IMultiLevelDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsVehicleConfig implements ILeadsVehicleConfig {
    metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
    ];

    useDefaultComparisonColumns = false;
        public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: Enums.leadMetrics.leadVolume.name,
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.leadSales.metricId,
                displayName: Enums.leadMetrics.leadSales.name,
                propertyName: Enums.leadMetrics.leadSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.percentOfLeads.metricId,
                displayName: Enums.leadMetrics.percentOfLeads.name,
                propertyName: Enums.leadMetrics.percentOfLeads.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.percentOfSales.metricId,
                displayName: Enums.leadMetrics.percentOfSales.name,
                propertyName: Enums.leadMetrics.percentOfSales.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        childMetrics: [], // this needs to be data driven

        defaultChildrenIds: [ 'All Classes', 'All Classes' ],
        defaultParentIds: [ Enums.leadMetrics.leadVolume.metricId, Enums.leadMetrics.leadSales.metricId ],
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Vehicle Class',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: true,
            header: 'Lead Volume',
            columnDef: 'leadVolume',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Lead Volume',
            columnDef: 'leadVolumePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lead Volume MOM',
            columnDef: 'leadVolumeMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Lead Volume',
            columnDef: 'leadVolumePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lead Volume YOY',
            columnDef: 'leadVolumeYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Lead Sales',
            columnDef: 'leadSales',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Lead Sales',
            columnDef: 'leadSalesPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lead Sales MOM',
            columnDef: 'leadSalesMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Lead Sales',
            columnDef: 'leadSalesPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lead Sales YOY',
            columnDef: 'leadSalesYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: '% of Leads',
            columnDef: 'percentOfLeads',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month % of Leads',
            columnDef: 'percentOfLeadsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: '% of Leads MOM',
            columnDef: 'percentOfLeadsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year % of Leads',
            columnDef: 'percentOfLeadsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: '% of Leads YOY',
            columnDef: 'percentOfLeadsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        } ,
        {
            show: true,
            header: '% of Sales',
            columnDef: 'percentOfSales',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month % of Sales',
            columnDef: 'percentOfSalesPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: '% of Sales MOM',
            columnDef: 'percentOfSalesMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev.Year % of Sales',
            columnDef: 'percentOfSalesPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: '% of Sales YOY',
            columnDef: 'percentOfSalesYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
    ];
}
