import { ILeadsFacebookConfig, IDefaultTableColumnConfig, IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsFacebookConfig implements ILeadsFacebookConfig {

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.facebookLeadMetrics.leadCount.metricId,
                displayName: Enums.facebookLeadMetrics.leadCount.name,
                propertyName: Enums.facebookLeadMetrics.leadCount.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.facebookLeadMetrics.saleCount.metricId,
                displayName: Enums.facebookLeadMetrics.saleCount.name,
                propertyName: Enums.facebookLeadMetrics.saleCount.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.facebookLeadMetrics.conversionRate.metricId,
                displayName: Enums.facebookLeadMetrics.conversionRate.name,
                propertyName: Enums.facebookLeadMetrics.conversionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        defaultMetricOneId: Enums.facebookLeadMetrics.leadCount.metricId,
        defaultMetricTwoId: Enums.facebookLeadMetrics.saleCount.metricId,
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Campaign',
            columnDef: 'campaignName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Class',
            columnDef: 'className',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Ad Set',
            columnDef: 'adSetName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Ad',
            columnDef: 'adName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Lead Count',
            columnDef: 'leadCount',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString,
        },
        {
            show: true,
            header: 'Sale Count',
            columnDef: 'saleCount',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString,
        },
        {
            show: true,
            header: 'Conversion Rate',
            columnDef: 'conversionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
    ];

}
