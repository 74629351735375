import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IPrintingOption, Enums } from '../../enums/enums';
import { ConfigurationService } from '../config/config.service';
import { IRouteConfig } from '../../models/models-index';



export const ROUTES_WHICH_EXPORT_TO_EXCEL: string[] = [
    Enums.routeNames.summaryReport,
    Enums.routeNames.sourcePerformanceReport,
    Enums.routeNames.dealerPerformanceReport,
    Enums.routeNames.transactionsReport,
    Enums.routeNames.digitalAdvertisingReport
];

@Injectable()
export class ExportsService {

    public exportButtonClicked = new Subject<IPrintingOption>();
    public exportButtonClicked$ = this.exportButtonClicked.asObservable();

    private routes: IRouteConfig[] = [];

    constructor(private configService: ConfigurationService) {
        this.routes = this.configService.routing.routingConfig.routes;
    }

    export(printingOption: IPrintingOption) {
        this.exportButtonClicked.next(printingOption);
    }

    excelAvailable(route: string): boolean {
        return  !!ROUTES_WHICH_EXPORT_TO_EXCEL.find(r => this.removeQueryParams(route).includes(r));
    }

    pdfAvailable(route: string): boolean {
        const routesWithPdfEnabled: string[] = [];

        this.routes.forEach(rt => {
            if (!!rt.children) {
                rt.children.forEach(child => {
                    if (child.pdfEnabled || false) {
                        routesWithPdfEnabled.push(`${child.parent}/${child.route}`);
                    }
                });
            }
        });

        return !!routesWithPdfEnabled.find(r => this.removeQueryParams(route).includes(r));
    }

    breadcrumbHelpAvailable(route: string): boolean {
        const routesWithHelpEnabled: string[] = [];

        this.routes.forEach(rt => {
            if (!!rt.children) {
                rt.children.forEach(child => {
                    if (child.breadcrumbHelpEnabled || false) {
                        routesWithHelpEnabled.push(`${child.parent}/${child.route}`);
                    }
                });
            }
        });

        return !!routesWithHelpEnabled.find(r => this.removeQueryParams(route).includes(r));
    }

    removeQueryParams(url: string): string {
        return url.split('?')[0];
    }

}
