import { Injectable } from '@angular/core';
import { AbbreviatedMetricPipe } from '../../pipes/abbreviatedMetric.pipe';
import { FormatMetricPipe } from '../../pipes/formatMetric.pipe';
import { DecimalNumberFormatPipe } from '../../pipes/decimalNumberFormat.pipe';
import { MinutesToHoursPipe } from '../../pipes/minutesToHours.pipe';
import { TimeStringPipe } from '../../pipes/timeString.pipe';
import { formatNumber, formatPercent } from '@angular/common';
import * as constants from '../../constants/constants';
import { FormatNumberPipe } from '../../pipes/formatNumber.pipe';
import * as moment from 'moment';
import { SdCurrencyPipe } from '../../pipes/currency.pipe';

@Injectable()
export class FormattingService {
  private defaultLocale = 'en';
  currency = (val) => SdCurrencyPipe.prototype.transform(val);
  currencyNoCents = (val) => SdCurrencyPipe.prototype.transform(val, 0);
  date = (val, loc) => val ? moment(val).locale(loc).format('MM/DD/YYYY') : '';
  dateTime = (val) => val ? moment(val).format('MM/DD/YYYY, h:mm:ss a') : '';
  dateTimeToMinute = (val) => val ? moment(val).format('MM/DD/YYYY hh:mm') : '';
  localeString = (val, loc?) => val ? val.toLocaleString(loc || this.defaultLocale) : '0';
  localeStringOrNa = (val, loc?) => !val ? 'N/A' : val.toLocaleString(loc || this.defaultLocale);
  abbreviatedMetricPipe = (val, loc) => AbbreviatedMetricPipe.prototype.transform(val, loc);
  formatMetricPipe = (val, loc) => FormatMetricPipe.prototype.transform(val, loc);
  decimalToPercentageTwoDecimals = (val) => FormatNumberPipe.prototype.transform(val, 'decimalToPercentTwoDecimals');
  roundToInteger = (val) => FormatNumberPipe.prototype.transform(val, 'roundToInteger');
  roundToIntegerLocaleString = (val) => FormatNumberPipe.prototype.transform(val, 'roundToInteger').toLocaleString();
  roundToThousandths = (val, loc) => formatNumber(val || 0, loc, '1.3-3');
  roundToHundredth = (val, loc) => formatNumber(val || 0, loc, '1.2-2');
  roundToTenth = (val, loc) => formatNumber(val || 0, loc, '1.1-1');
  roundNumber = (val, loc) => formatNumber(val || 0, loc, '0.0-0');
  hoursTimeStringFromMinutes = (val) => MinutesToHoursPipe.prototype.transform(val);
  minutesTimeString = (val) => TimeStringPipe.prototype.transform(val, 'minutes');
  minutesTimeStringFromSeconds = (val) => TimeStringPipe.prototype.transform(val, 'seconds');
  timePipe = (val) => TimeStringPipe.prototype.transform(val, 'minutes');
  percentPipe = (val, loc) => formatPercent(val || 0, loc);
  percentPipeNoDecimals = (val, loc?) => formatPercent(val || 0, loc || this.defaultLocale, '.0');
  percentPipeOneDecimal = (val, loc?) => formatPercent(val || 0, loc || this.defaultLocale, '.1');
  percentPipeTwoDecimals = (val, loc?) => formatPercent(val || 0, loc || this.defaultLocale, '.2');
  percentPipeTwoDecimalsOrNa = (val, loc?) => !val ? 'N/A' : formatPercent(val || 0, loc || this.defaultLocale, '.2');
  integerDaysOrNa = (val) => !val ? 'N/A' : FormatNumberPipe.prototype.transform(val, 'roundToInteger') + ' Days';
  oneDecimalDaysOrNa = (val, loc) => !val ? 'N/A' : DecimalNumberFormatPipe.prototype.transform(val, 1) + this.getDaysSuffix(loc);
  stringOrNa = (val) => !val ? 'N/A' : '' + val;

  constructor() { }

  public getFormatter(metricName: string, locale?: string): any {

    // JMSTODO: Until we finish all of the app localization, we need to default locale to en
    locale = !!locale ? locale : 'en';

    switch (metricName) {
      case constants.formatKeys.currency:
        return this.currency;
      case constants.formatKeys.currencyNoCents:
        return this.currencyNoCents;
      case constants.formatKeys.date:
        return (val) => this.date(val, locale);
      case constants.formatKeys.dateTime:
        return this.dateTime;
      case constants.formatKeys.dateTimeToMinute:
        return this.dateTimeToMinute;
      case constants.formatKeys.decimalToPercentTwoDecimals:
        return this.decimalToPercentageTwoDecimals;
      case constants.formatKeys.entityDisplayName:
        return (val) => this.getDisplayNameForEntity(val, 'upper');
      case constants.formatKeys.entityDisplayNameNormalCase:
        return (val) => this.getDisplayNameForEntity(val, null);
      case constants.formatKeys.integerDaysOrNa:
        return (val) => this.integerDaysOrNa(val);
      case constants.formatKeys.localeString:
        return (val) => this.localeString(val, locale);
      case constants.formatKeys.localeStringOrNa:
        return (val) => this.localeStringOrNa(val, locale);
      case constants.formatKeys.abbreviatedLocaleString:
        return (val) => this.abbreviatedMetricPipe(val, locale);
      case constants.formatKeys.minutesTimeString:
        return this.minutesTimeString;
      case constants.formatKeys.hoursTimeStringFromMinutes:
        return this.hoursTimeStringFromMinutes;
      case constants.formatKeys.oneDecimalDaysOrNa:
        return (val) => this.oneDecimalDaysOrNa(val, locale);
      case constants.formatKeys.percentage: // this isn't great - rounds up to zero
        return (val) => this.percentPipe(val, locale);
      case constants.formatKeys.percentage:
        return (val) => this.percentPipeTwoDecimals(val, locale);
      case constants.formatKeys.minutesTimeStringFromSeconds:
        return this.minutesTimeStringFromSeconds;
      case constants.formatKeys.hourMinuteString:
        return this.minutesTimeStringFromSeconds;
      case constants.formatKeys.percentageNoDecimals:
        return (val) => this.percentPipeNoDecimals(val, locale);
      case constants.formatKeys.percentageOneDecimal:
        return (val) => this.percentPipeOneDecimal(val, locale);
      case constants.formatKeys.percentageTwoDecimals:
        return (val) => this.percentPipeTwoDecimals(val, locale);
      case constants.formatKeys.percentageTwoDecimalsOrNa:
        return (val) => this.percentPipeTwoDecimalsOrNa(val, locale);
      case constants.formatKeys.roundToHundreth:
        return (val) => this.roundToHundredth(val, locale);
      case constants.formatKeys.roundToInteger:
        return this.roundToInteger;
      case constants.formatKeys.roundToIntegerLocaleString:
        return this.roundToIntegerLocaleString;
      case constants.formatKeys.roundNumber:
        return (val) => this.roundNumber(val, locale);
      case constants.formatKeys.roundToTenth:
        return (val) => this.roundToTenth(val, locale);
      case constants.formatKeys.roundToThousandths:
        return (val) => this.roundToThousandths(val, locale);
      case constants.formatKeys.stringOrNa:
        return (val) => this.stringOrNa(val);
      default:
        return (val) => this.formatMetricPipe(val, locale);
    }
  }

  getDaysSuffix(locale) {
    return locale === 'fr-CA' ? ' Journées' : ' Days'
  }

  getDisplayNameForEntity(entity: string, casing: string): string {
    if (casing === 'upper')
      entity = !!entity && entity !== undefined ? entity.toUpperCase() : '';
    else if (casing === 'lower')
      entity = !!entity && entity !== undefined ? entity.toLowerCase() : '';
    else
      entity = !!entity && entity !== undefined ? entity : '';

    return entity;
  }

  getTrendArrowIconClass(trend: number): string {
    if (trend === 0) {
      return 'fa fa-arrows-h';
    } else if (trend < 0) {
      return 'fa fa-level-down';
    } else {
      return 'fa fa-level-up';
    }
  }

}
