import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
  NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
  NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
  showEntireOrgHierarchyForAllUsers = false;
  defaultMonthDateMode: DateMonthModes = 'calendar';
  filterTypeLookup: { [id: string]: FilterType } = {
    'org': { displayName: 'Hierarchy', name: 'org' },
    'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
    'device-type': { displayName: 'Device Type', name: 'device-type' },
    'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
    'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
    'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
    'source-type': { displayName: 'Source Type', name: 'source-type' },
    'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
    'vehicle-class': { displayName: 'Vehicle', name: 'vehicle-class' },
    'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
    'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
    'website-provider': { displayName: 'Provider', name: 'website-provider' },
  };

  filterReportConfigs: { [id: string]: FilterConfigReport } = {
    // Chat
    'ChatOverview': {
        dateMode: Enums.dateModes.currentMonth,
        restrictDates: true,
        reportName: constants.reportNames.chatOverview,
        filters: ['org', 'device-type'],
        defaults: ['org'],
        section: 'provider-tools',
        orgTypes: [1]
    },        
    // Digital Retailing
    'DigitalRetailingOverview': {
      reportName: constants.reportNames.digitalRetailingOverview,
      dateMode: Enums.dateModes.currentMonth,
      filters: ['org', 'digital-retailing-provider', 'device-type'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1]
    },
    // Inventory
    'InventoryOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.inventoryOverview,
      filters: [
        'org', 'vehicle'
      ],
      defaults: [
        'org', 'vehicle'
      ],
      section: 'inventory',
      defaultMonthDateMode: 'sales',
      disableCalendarMode: true
    },
    // Leads
    'LeadsOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.leadsOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'leads'
    },
    'LeadSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadSummary,
      filters: [
        'org', 'lead-type', 'source-type', 'vehicle-make'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    'LeadSourcePerformance': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadSourcePerformance,
      filters: [
        'org', 'lead-type', 'source-type', 'vehicle-make'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    'LeadEventPerformance': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.leadEventPerformance,
      filters: [
        'org', 'vehicle-make'
      ],
      defaults: [
        'org'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    'FacebookPerformance': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.facebookPerformance,
      filters: [
        'org'
      ],
      defaults: [
        'org'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    'LeadVehiclePerformance': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadVehiclePerformance,
      filters: [
        'org', 'source-type', 'vehicle-make'
      ],
      defaults: [
        'org', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    'LeadTransactions': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadTransactions,
      filters: [
        'org', 'lead-type', 'source-type', 'vehicle-make'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    // Sales
    'SalesOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.salesOverview,
      filters: ['org', 'lead-type'],
      defaults: ['org', 'lead-type'],
      section: 'sales',
      orgTypes: [1],
      defaultMonthDateMode: 'sales'
    },
    'SalesSummary': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.salesSummary,
      filters: [
        'org', 'sale-type', 'lead-type', 'source-type', 'vehicle-class'
      ],
      defaults: [
        'org', 'sale-type', 'lead-type', 'source-type'
      ],
      section: 'sales',
      orgTypes: [1],
      defaultMonthDateMode: 'sales'
    },
    'SalesVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.salesVehicleSummary,
      filters: [
        'org', 'lead-type', 'sale-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'sale-type', 'source-type'
      ],
      section: 'sales',
      orgTypes: [1],
      defaultMonthDateMode: 'sales'
    },
    // Website
    'WebsiteOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteAfterSales': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteAfterSales,
      filters: [
        'org', 'device-type'
      ],
      defaults: [
        'org', 'device-type'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteCertifiedChat': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteCertifiedChat,
      filters: [
        'org', 'chat-provider'
      ],
      defaults: [
        'org', 'chat-provider'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteLandingPage': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteLandingPage,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1]
    },

    'WebsiteProviderSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteProviderSummary, // org only
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteReferrerSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteReferrerSummary,
      filters: [
        'org', 'device-type'
      ],
      defaults: [
        'org', 'device-type'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteScorecard': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteScorecard,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteSummary,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteVehicleSummary,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1]
    }
  };

  FILTER_CONFIG: { [id: string]: Filter} = {
    'org': {
      type: 'org',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'vehicle': {
      type: 'vehicle',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'chat-provider': {
      type: 'chat-provider',
      selected: [
        // { value: 15, display: 'ActivEngage' },
        // { value: 16, display: 'CarChat24' },        
        // { value: 17, display: 'ContactAtOnce' },
        // { value: 24, display: 'Conversations' },
        // { value: 18, display: 'Gubagoo' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'device-type': {
      type: 'device-type',
      selected: [
        { value: 1, display: 'Desktop' },
        { value: 2, display: 'Tablet' },
        { value: 3, display: 'Phone' }
      ],
      removable: true,
      lockable: true,
      locked: false
    },
    'digital-retailing-provider': {
      type: 'digital-retailing-provider',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },    
    'lead-type': {
      type: 'lead-type',
      selected: [{ value: 1, display: 'New Vehicle' }],
      removable: false,
      lockable: true,
      locked: false
    },
    'sale-type': {
      type: 'sale-type',
      selected: [{ value: 1, display: 'New' }],
      removable: false,
      lockable: true,
      locked: false
    },
    'source-type': {
      type: 'source-type',
      selected: [
        { value: 1, display: 'Other Brand' },
        { value: 2, display: '3rd Party' },
        { value: 3, display: 'Dealer Website' },
        { value: 7, display: 'MBUSA.com' },
        { value: 8, display: 'Digital Retailing' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'vehicle-make': {
      type: 'vehicle-make',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-class': {
      type: 'vehicle-class',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-model': {
      type: 'vehicle-model',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'website-provider': {
      type: 'website-provider',
      available: [1, 2, 3, 4],
      selected: [
        { value: 1, display: 'DealerFire' },
        { value: 2, display: 'Dealer Inspire' },
        { value: 3, display: 'DealerOn' },
        { value: 4, display: 'Dealer.com' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
  };
}
