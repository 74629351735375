import { ILeadsEventConfig, IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsEventConfig implements ILeadsEventConfig {
    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: Enums.leadMetrics.leadVolume.name,
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.leadSales.metricId,
                displayName: Enums.leadMetrics.leadSales.name,
                propertyName: Enums.leadMetrics.leadSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.conversionRate.metricId,
                displayName: Enums.leadMetrics.conversionRate.name,
                propertyName: Enums.leadMetrics.conversionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: Enums.leadMetrics.leadSales.metricId,
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    };

    summaryTableColumns: [] = [];


    // metrics come from data
    public trendTableMetricKeys: string[] = [

    ];
}
