import { ILeadTransactionsConfig, IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadTransactionsConfig implements ILeadTransactionsConfig {

    public maskSameDealerSales: boolean = true;
    
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Lead Date',
            columnDef: 'created',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        {
            show: true,
            header: 'Dealer Code',
            columnDef: 'dealerCode',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'Lead Source',
            columnDef: 'sourceName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'Customer Name',
            columnDef: 'customerName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: true,
            header: 'City',
            columnDef: 'city',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'State',
            columnDef: 'state',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'Model',
            columnDef: 'vehicleModel',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'Live Date',
            columnDef: 'liveDate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        {
            show: true,
            header: 'Responded',
            columnDef: 'responded',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        {
            show: true,
            header: 'Time to Respond',
            columnDef: 'timeToRespond',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.hoursTimeStringFromMinutes,
            clickable: false
        },
        {
            show: true,
            header: 'Sale Date',
            columnDef: 'saleDate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.date,
            clickable: false
        },
        {
            show: true,
            header: 'Sale Model',
            columnDef: 'saleModel',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'Sale Dealer',
            columnDef: 'saleDealerCode',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
    ];

}
