<div class="row print-report-header">
    <div class="col-md-11 pull-left row print-report-title">
      {{reportName$ | async}}
    </div>
    <div class="col-md-11 pull-left row print-report-breadcrumb">
      {{printBreadcrumb}}
    </div>
    <div class="col-md-1 row print-report-logo pull-right">
        <div><img [src] = "brandLogo"></div>
    </div>
  </div>