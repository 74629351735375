import { IDefaultTableColumnConfig, ISourceSummaryConfig, IDualAxisLineGraphConfig, INameValuePair } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class SourceSummaryConfig implements ISourceSummaryConfig {

    public reportTitle = 'Source Performance';

    metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' },
        { name: 'Trend', value: 'Trend' }
  ];

    hasTrendTable = true;

    useDefaultComparisonColumns = false;

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: Enums.leadMetrics.leadVolume.name,
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.averageDailyVolume.metricId,
                displayName: Enums.leadMetrics.averageDailyVolume.name,
                propertyName: Enums.leadMetrics.averageDailyVolume.nameKey,
                metricFormatKey: constants.formatKeys.roundToTenth
            },
            {
                id: Enums.leadMetrics.leadSales.metricId,
                displayName: Enums.leadMetrics.leadSales.name,
                propertyName: Enums.leadMetrics.leadSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.closeRate.metricId,
                displayName: Enums.leadMetrics.closeRate.name,
                propertyName: Enums.leadMetrics.closeRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: Enums.leadMetrics.leadSales.metricId,
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    }
    public trendTableMetrics: INameValuePair[] = [
      {name: 'Leads', value: 'leadVolume'},
      {name: 'Lead Sales', value: 'leadSales'},
      {name: 'Close Rate', value: 'closeRate' },
      {name: 'Avg. Daily Volume', value: 'averageLeadsPerDay' },
    ];

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false ,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },
        {
            show: true,
            header: 'Leads',
            columnDef: 'leadVolume',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Leads',
            columnDef: 'leadVolumePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Leads MOM',
            columnDef: 'leadVolumeMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Leads',
            columnDef: 'leadVolumePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Leads YOY',
            columnDef: 'leadVolumeYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Lead Sales',
            columnDef: 'leadSales',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Lead Sales',
            columnDef: 'leadSalesPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lead Sales MOM',
            columnDef: 'leadSalesMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Lead Sales',
            columnDef: 'leadSalesPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lead Sales YOY',
            columnDef: 'leadSalesYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Close Rate',
            columnDef: 'closeRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Close Rate',
            columnDef: 'closeRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Close Rate MOM',
            columnDef: 'closeRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Close Rate',
            columnDef: 'closeRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Close Rate YOY',
            columnDef: 'closeRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Ave. Daily Volume',
            columnDef: 'averageLeadsPerDay',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Ave. Daily Volume',
            columnDef: 'averageLeadsPerDayPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Ave. Daily Volume MOM',
            columnDef: 'averageLeadsPerDayMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Ave. Daily Volume',
            columnDef: 'averageLeadsPerDayPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Ave. Daily Volume YOY',
            columnDef: 'averageLeadsPerDayYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
    ];
}
