<spinner-overlay></spinner-overlay>
<div *ngIf="({ value: isPrintMode$ | async }) as isPrintMode">
  <layout-navbar [ngClass]="{ 'print-mode-navbar':  isPrintMode.value }" *ngIf="!isPrintMode.value"></layout-navbar>
  <div class="app-body" [ngClass]="{ 'print-mode-report':  isPrintMode.value }">
    <layout-sidebar *ngIf="!isPrintMode.value"></layout-sidebar>
    <!-- Main content -->
    <main class="main">
      <!-- Breadcrumb -->
      <layout-breadcrumb *ngIf="showBreadcrumb$ | async"></layout-breadcrumb>
      <app-print-header *ngIf="isPrintMode.value"></app-print-header>
      <app-filter-bar *ngIf="showFilterBar$ | async"></app-filter-bar>
      <div *ngIf="layoutHelper.sideNavToggleAction$ | async as toggleStatus"
        [ngClass] = "(showFilterBar$ | async) ? 'content-container' : 'content-container-no-filter'">
          <!-- Router Content -->
          <div [@contentSlide]="toggleStatus" class="router-container" *ngIf="!isPrintMode.value">
            <div class="content-divider" *ngIf="showFilterBar$ | async"></div>
            <router-outlet></router-outlet>
          </div>
          <div class="router-container" *ngIf="isPrintMode.value">
            <router-outlet></router-outlet>
          </div>
      </div>
    </main>
  </div>
  <layout-footer *ngIf="!isPrintMode.value"></layout-footer>
</div>
