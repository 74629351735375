import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from '../appInsights/appInsights.service';

@Injectable()
export class LeadsOverviewService {

    constructor(
      private http: HttpClient,
      private appInsights: AppInsightsService) { }

    getLeadsOverviewBillboardMetrics(reportRequest: any): Observable<Models.LeadOverviewBillboardResponseModel> {
        return this.http.post<Models.LeadOverviewBillboardResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getLeadsOverviewDealerChartMetrics(reportRequest: any): Observable<Models.DealerLeadOverviewChartResponseModel> {
        return this.http.post<Models.DealerLeadOverviewChartResponseModel>(
          environment.baseApiUri + '/report',
          JSON.stringify(reportRequest),
          { headers: { 'Content-Type': 'application/json' } }).pipe(
            catchError(err => {
                appInsights.trackException(err);
                return  this.handleError(err);
            })
        );
    }

    getLeadsOverviewBenchmarkMetrics(reportRequest: any): Observable<Models.ReportResponseModel> {
        return this.http.post<Models.ReportResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getLeadsOverviewBenchmarkLookbackMetrics(reportRequest: any): Observable<Models.ReportResponseModel> {
        return this.http.post<Models.ReportResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return this.handleError(err);
                })
            );
    }
    getLeadsOverviewSourceTypeMetrics(reportRequest: any): Observable<Models.LeadOverviewSourceTypeMetricResponseModel> {
        return this.http.post<Models.LeadOverviewSourceTypeMetricResponseModel>(
            environment.baseApiUri + '/report',
            JSON.stringify(reportRequest),
            { headers: { 'Content-Type': 'application/json' } }).pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    private handleError(err: any) {
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else if (err.body) {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        } else {
            errorMessage = JSON.stringify(err);
        }
        this.appInsights.trackException(err);
        console.error(err);
        return throwError(errorMessage);
    }
}
