import {
  ILeadsOverviewBillboardConfig,
  ILeadsOverviewConfig,
  ILeadsOverviewDealerKpiConfig,
  IDefaultTableColumnConfig,
  ILeadsOverviewKpiConfig,
  ILeadsOverviewSourceTypeCardConfig
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class LeadsOverviewConfig implements ILeadsOverviewConfig {
  public reportTitle = 'Leads Overview';
  metricDisplayModes = [
    {name: 'MOM', value: 'MOM'},
    {name: 'YOY', value: 'YOY'},
    {name: '60-Day', value: 'Lookback'}
  ];
  public useDefaultComparisonColumns = true;
  public showSourceTypeComparisonMetrics = true;
  public sourceTypeCardConfig: ILeadsOverviewSourceTypeCardConfig = {
    showComparisonMetrics: true,
    leadMetric: {
      name: 'Unique Leads',
      value: 'uniqueLeads'
    },
    closeRateMetric: {
      name: 'Close Rate',
      value: 'bestMatchCloseRate'
    }
  }
  
  lookbackMonths = 3;
  public billboards: ILeadsOverviewBillboardConfig[] = [
    {
      title: 'Lead Volume',
      subtitle: '',
      iconClass: 'fa-user fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousYearPropertyName: 'leadVolumePreviousYear',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'LeadsOverviewLeadVolumeBillboardHelp',
      helpTextTitle: 'Lead Volume'
    },
    {
      title: 'Responded < 40 Min',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'responseRate40',
      metricMOMPropertyName: 'responseRate40MOM',
      metricYOYPropertyName: 'responseRate40YOY',
      metricPreviousMonthPropertyName: 'responseRate40PreviousMonth',
      metricPreviousMTDPropertyName: 'responseRate40PreviousMTD',
      metricPreviousYearPropertyName: 'responseRate40PreviousYear',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'responseRate40MOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewResponded40BillboardHelp',
      helpTextTitle: 'Responded Less Than 40'
    },
    {
      title: 'Appointment Rate',
      subtitle: '',
      iconClass: 'fa-calendar fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'appointmentRate',
      metricMOMPropertyName: 'appointmentRateMOM',
      metricYOYPropertyName: 'appointmentRateYOY',
      metricPreviousMonthPropertyName: 'appointmentRatePreviousMonth',
      metricPreviousMTDPropertyName: 'appointmentRatePreviousMTD',
      metricPreviousYearPropertyName: 'appointmentRatePreviousYear',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'appointmentRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewAppointmentRateBillboardHelp',
      helpTextTitle: 'Appointment Rate'
    },
    {
      title: 'Close Rate',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'bestMatchCloseRate',
      metricMOMPropertyName: 'bestMatchCloseRateMOM',
      metricYOYPropertyName: 'bestMatchCloseRateYOY',
      metricPreviousMonthPropertyName: 'bestMatchCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'bestMatchCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'bestMatchCloseRatePreviousYear',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'bestMatchCloseRateMOM',
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      helpTextKey: 'LeadsOverviewCloseRateBillboardHelp',
      helpTextTitle: 'Close Rate'
    }
  ];
  public dealerKpiCardConfig: ILeadsOverviewDealerKpiConfig = {
    metrics: {
      'CloseRate': {
        yAxisTitle: 'Close Rate %',
        displayName: 'Close Rate %',
        pointLabelFormat: null,
        propertyName: 'sameDealerBestMatchCloseRate',
        metricFormatKey: null,
        formatter: null
      },
      'LeadVolume': {
        yAxisTitle: 'Lead Volume',
        displayName: 'Lead Volume',
        pointLabelFormat: null,
        propertyName: 'leadVolume',
        metricFormatKey: null,
        formatter: null
      },
      'ResponseRate': {
        yAxisTitle: 'Responded < 40 Min',
        displayName: 'Responded < 40 Min',
        pointLabelFormat: null,
        propertyName: 'responseRate40',
        metricFormatKey: null,
        formatter: null
      }
    }
  };

  public kpiCardConfig: ILeadsOverviewKpiConfig = {
    metrics: {
      'ResponseRate': {
        yAxisTitle: '% Responded < 40 Min',
        displayName: 'Response Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'responseRate40',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: (val) => Math.round(val * 100)
      },
      'CloseRate': {
        yAxisTitle: 'Close Rate',
        displayName: 'Close Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: (val) => Math.round(val * 10000) / 100
      }
    }
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },

    // Lead Volume
    {
      show: true, header: 'Total Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    // Unique Lead Volume
    {
      show: true, header: 'Unique Leads',
      columnDef: 'uniqueLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    
    // Lead Sales
    {
      show: true, header: 'Lead Sales',
      columnDef: 'bestMatchSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    
    // Response Rate
    {
      show: true,
      header: 'Response Rate < 40 Min',
      columnDef: 'responseRate40',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    

    // Average Response Time
    {
      show: true,
      header: 'Avg Response Time',
      columnDef: 'averageResponseTime48Hour',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
    },
    
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    
    // 60 Day Close Rate
    {
      show: true,
      header: '60-Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    
    // 90 Day Close Rate
    {
      show: true,
      header: '90-Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    
  ];

  public dealerBenchmarkLookbackColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    // Lead Volume
    {
      show: true, header: 'Total Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      isMom: false,
      isYoy: false,
      isLookback: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    
    // Unique Lead Volume
    {
      show: true, header: 'Unique Leads',
      columnDef: 'uniqueLeads',
      hasComparisonMetrics: true,
      isMom: false,
      isYoy: false,
      isLookback: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    
    // Lead Sales
    {
      show: true, header: 'Lead Sales',
      columnDef: 'bestMatchSales',
      hasComparisonMetrics: true,
      isMom: false,
      isYoy: false,
      isLookback: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    
    // Appointments
    {
      show: true,
      header: 'Appointments',
      columnDef: 'appointments',
      hasComparisonMetrics: true,
      isMom: false,
      isYoy: false,
      isLookback: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      hasComparisonMetrics: true,
      isMom: false,
      isYoy: false,
      isLookback: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      hasComparisonMetrics: true,
      isMom: false,
      isYoy: false,
      isLookback: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    
    // 60 Day Close Rate
    {
      show: true,
      header: '60-Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      hasComparisonMetrics: true,
      isMom: false,
      isYoy: false,
      isLookback: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    
    // 90 Day Close Rate
    {
      show: true,
      header: '90-Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      hasComparisonMetrics: true,
      isMom: false,
      isYoy: false,
      isLookback: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },    
  ];
}
