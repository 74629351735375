import { ISaleType, ReportRequestModel } from './../../models/models';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Enums, IEnums } from '../../enums/enums';
import * as Models from '../../models/models-index';
import { LocalCacheService } from '../cache/localCache.service';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from '../appInsights/appInsights.service';
import { throwError, Observable } from 'rxjs';

@Injectable()
export class ReportsService {

    orgLookups$ = this.http.get<Models.IOrgLookup[]>
        (environment.baseApiUri + '/getOrgLookups').pipe(
            catchError(err => {
                this.appInsights.trackException(err);
                return throwError(err);
            })
        );

    constructor(
        private http: HttpClient,
        private cacheService: LocalCacheService,
        private appInsights: AppInsightsService) { }

    getReport(reportRequest: ReportRequestModel, reportType: string): Observable<Models.ReportResponseModel> {
        reportRequest.reportType = reportType;
        return this.http.post<Models.ReportResponseModel>(
          environment.baseApiUri + '/report',
          reportRequest,
          { headers: { 'Content-Type': 'application/json' } }).pipe(
              catchError(err => {
                  console.log(reportType, err);
                  appInsights.trackException(err);
                  return this.handleError(err);
              })
          );
    }

    // All of this could be consolidated into a single config call that returned all of these elements at once.
    // They're parameter-less
    getAppConfig(): Observable<Models.IAppConfig> {
        return this.http.get<Models.IAppConfig>(environment.baseApiUri + '/getAppConfig')
          .pipe(
                catchError(this.handleError));
    }

    private handleError(err: any) {
        let errorMessage: string;
        if (!!err.error && err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err?.status}: ${err?.body?.error}`;
        }
        this.appInsights.trackException(err);
        console.error(err);
        return throwError(errorMessage);
    }
}
