import {
    IInventoryOverviewBillboardConfig,
    IInventoryOverviewConfig,
    IDefaultTableColumnConfig,
    IInventoryOverviewKpiCardConfig,
    ILeadsOverviewKpiConfig } from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class InventoryOverviewConfig implements IInventoryOverviewConfig {

    public billboards: IInventoryOverviewBillboardConfig[] = [
        {
            title: 'Health Score',
            subtitle: 'Inventory Health',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricFormatKey: constants.formatKeys.percentage,
            metricPropertyName: 'healthScore',
            metricPropertyId: Enums.inventoryMetrics.healthScore.metricId,
            metricMOMPropertyName: 'healthScoreMOM',
            metricPreviousMonthPropertyName: 'healthScorePreviousMonth',
            footerLabel: 'Last Month / ',
            footerMetricFormatKey: constants.formatKeys.percentage,
            graphMetricFormatKey: constants.formatKeys.percentage,
            helpTextKey: 'InventoryOverviewBillboardHealthScoreHelp',
            helpTextTitle: 'Health Score'
        },
        {
            title: 'Inventory',
            subtitle: 'Vehicles',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricFormatKey: constants.formatKeys.localeString,
            metricPropertyName: 'inventory',
            metricPropertyId: Enums.inventoryMetrics.inventory.metricId,
            metricMOMPropertyName: 'inventoryMOM',
            metricPreviousMonthPropertyName: 'inventoryPreviousMonth',
            footerLabel: 'Last Month / ',
            footerMetricFormatKey: constants.formatKeys.localeString,
            graphMetricFormatKey: constants.formatKeys.localeString,
            helpTextKey: 'InventoryOverviewBillboardInventoryLevelHelp',
            helpTextTitle: 'Inventory Level'
        },
        {
            title: 'Demand',
            subtitle: 'Av. VDP / Av. VIN',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricFormatKey: constants.formatKeys.roundToHundreth,
            metricPropertyName: 'demand',
            metricPropertyId: Enums.inventoryMetrics.demand.metricId,
            metricMOMPropertyName: 'demandMOM',
            metricPreviousMonthPropertyName: 'demandPreviousMonth',
            footerLabel: 'Last Month / ',
            footerMetricFormatKey: constants.formatKeys.roundToHundreth,
            graphMetricFormatKey: constants.formatKeys.roundToHundreth,
            helpTextKey: 'InventoryOverviewBillboardAvgVDPPerAvgVINHelp',
            helpTextTitle: 'Avg. VDP Per Avg. VIN'
        },
        {
            title: 'Days Supply',
            subtitle: 'Days',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricFormatKey: constants.formatKeys.localeString,
            metricPropertyName: 'daysSupply',
            metricPropertyId: Enums.inventoryMetrics.demand.metricId,
            metricMOMPropertyName: 'daysSupplyMOM',
            metricPreviousMonthPropertyName: 'daysSupplyPreviousMonth',
            footerLabel: 'Last Month / ',
            footerMetricFormatKey: constants.formatKeys.localeString,
            graphMetricFormatKey: constants.formatKeys.localeString,
            helpTextKey: 'InventoryOverviewBillboardDaysSupplyHelp',
            helpTextTitle: 'Days Supply'
        },
    ];
    public inventorySummaryColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true,
        },
        {
            show: true,
            header: 'Health Score',
            columnDef: 'healthScore',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Health Score MOM',
            columnDef: 'healthScoreMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Health Score',
            columnDef: 'healthScorePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Health Score YOY',
            columnDef: 'healthScoreYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Health Score',
            columnDef: 'healthScorePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: true,
            header: 'Comment Score',
            columnDef: 'commentScore',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Comment Score MOM',
            columnDef: 'commentScoreMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Comment Score',
            columnDef: 'commentScorePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Comment Score YOY',
            columnDef: 'commentScoreYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Comment Score',
            columnDef: 'commentScorePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: true,
            header: 'Photo Score',
            columnDef: 'photoScore',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Photo Score MOM',
            columnDef: 'photoScoreMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Photo Score',
            columnDef: 'photoScorePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Photo Score YOY',
            columnDef: 'photoScoreYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Photo Score',
            columnDef: 'photoScorePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: true,
            header: 'Cars on Lot',
            columnDef: 'inventory',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Cars on Lot MOM',
            columnDef: 'inventoryMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Cars on Lot',
            columnDef: 'inventoryPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Cars on Lot YOY',
            columnDef: 'inventoryYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Cars on Lot',
            columnDef: 'inventoryPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: true,
            header: 'VDP Views',
            columnDef: 'vdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'VDP Views MOM',
            columnDef: 'vdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month VDP Views',
            columnDef: 'vdpViewsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'VDP Views YOY',
            columnDef: 'vdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year VDP Views',
            columnDef: 'vdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: true,
            header: 'Av. VDP / Av. VIN',
            columnDef: 'demand',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
        },
        {
            show: false,
            header: 'Av. VDP / Av. VIN MOM',
            columnDef: 'demandMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Av. VDP / Av. VIN',
            columnDef: 'demandPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
        },
        {
            show: false,
            header: 'Av. VDP / Av. VIN YOY',
            columnDef: 'demandYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Av. VDP / Av. VIN',
            columnDef: 'demandPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
        },
        {
            show: true,
            header: 'Days Supply',
            columnDef: 'daysSupply',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Days Supply MOM',
            columnDef: 'daysSupplyMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Days Supply',
            columnDef: 'daysSupplyPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Days Supply YOY',
            columnDef: 'daysSupplyYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Days Supply',
            columnDef: 'daysSupplyPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: true,
            header: 'Av. MSRP',
            columnDef: 'averageMsrp',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Av. MSRP MOM',
            columnDef: 'averageMsrpMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Av. MSRP',
            columnDef: 'averageMsrpPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Av. MSRP YOY',
            columnDef: 'averageMsrpYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Av. MSRP',
            columnDef: 'averageMsrpPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: true,
            header: '% Sales Target',
            columnDef: 'percentOfSalesTarget',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageNoDecimals, clickable: false
        },
        {
            show: false,
            header: '% Sales Target MOM',
            columnDef: 'percentOfSalesTargetMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month % Sales Target',
            columnDef: 'percentOfSalesTargetPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageNoDecimals, clickable: false
        },
        {
            show: false,
            header: '% Sales Target YOY',
            columnDef: 'percentOfSalesTargetYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year % Sales Target',
            columnDef: 'percentOfSalesTargetPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageNoDecimals, clickable: false
        }
    ];
    public classSummaryColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            print: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },
        {
            show: true,
            header: 'Photo Score',
            columnDef: 'photoScore',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Photo Score MOM',
            columnDef: 'photoScoreMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Photo Score',
            columnDef: 'photoScorePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Photo Score YOY',
            columnDef: 'photoScoreYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Photo Score',
            columnDef: 'photoScorePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: true,
            header: 'Comment Score',
            columnDef: 'commentScore',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Comment Score MOM',
            columnDef: 'commentScoreMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Comment Score',
            columnDef: 'commentScorePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: false,
            header: 'Comment Score YOY',
            columnDef: 'commentScoreYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Comment Score',
            columnDef: 'commentScorePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentage, clickable: false
        },
        {
            show: true,
            header: 'Cars on Lot',
            columnDef: 'inventory',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Cars on Lot MOM',
            columnDef: 'inventoryMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Cars on Lot',
            columnDef: 'inventoryPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Cars on Lot YOY',
            columnDef: 'inventoryYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Cars on Lot',
            columnDef: 'inventoryPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: true,
            header: 'VDP Views',
            columnDef: 'vdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'VDP Views MOM',
            columnDef: 'vdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month VDP Views',
            columnDef: 'vdpViewsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'VDP Views YOY',
            columnDef: 'vdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year VDP Views',
            columnDef: 'vdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: true,
            header: 'Av. VDP / Av. VIN',
            columnDef: 'demand',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
        },
        {
            show: false,
            header: 'Av. VDP / Av. VIN MOM',
            columnDef: 'demandMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Av. VDP / Av. VIN',
            columnDef: 'demandPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
        },
        {
            show: false,
            header: 'Av. VDP / Av. VIN YOY',
            columnDef: 'demandYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Av. VDP / Av. VIN',
            columnDef: 'demandPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
        },
        {
            show: true,
            header: 'Av. MSRP',
            columnDef: 'averageMsrp',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Av. MSRP MOM',
            columnDef: 'averageMsrpMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Av. MSRP',
            columnDef: 'averageMsrpPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Av. MSRP YOY',
            columnDef: 'averageMsrpYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Av. MSRP',
            columnDef: 'averageMsrpPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
    ];
    public modelSummaryColumns: IDefaultTableColumnConfig[];
    public inventoryKpiCardConfig: IInventoryOverviewKpiCardConfig;
}
