import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, INameValuePair } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {

    public metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' },
        { name: 'Trend', value: 'Trend' }
    ];

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: Enums.leadMetrics.leadVolume.name,
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.responseRate.metricId,
                displayName: Enums.leadMetrics.responseRate.name,
                propertyName: Enums.leadMetrics.responseRate.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.responseRate.metricId,
                displayName: Enums.leadMetrics.responseRate.name,
                propertyName: Enums.leadMetrics.responseRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate40.metricId,
                displayName: Enums.leadMetrics.responseRate40.name,
                propertyName: Enums.leadMetrics.responseRate40.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.averageResponseTime48Hour.metricId,
                displayName: Enums.leadMetrics.averageResponseTime48Hour.name,
                propertyName: 'averageResponseTime48Hour',
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            {
                id: Enums.leadMetrics.closeRate.metricId,
                displayName: Enums.leadMetrics.closeRate.name,
                propertyName: 'bestMatchCloseRate',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate30.metricId,
                displayName: Enums.leadMetrics.closeRate30.name,
                propertyName: 'bestMatchCloseRate30',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate60.metricId,
                displayName: Enums.leadMetrics.closeRate60.name,
                propertyName: 'bestMatchCloseRate60',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate90.metricId,
                displayName: Enums.leadMetrics.closeRate90.name,
                propertyName: 'bestMatchCloseRate90',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.closeRate120.metricId,
                displayName: Enums.leadMetrics.closeRate120.name,
                propertyName: 'bestMatchCloseRate120',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            }
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: Enums.leadMetrics.closeRate.metricId,
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },
        // can use row to determine entity type if needed
        {
            show: true,
            header: 'Total Leads',
            columnDef: 'leadVolume',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Total Leads',
            columnDef: 'leadVolumePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Leads MOM',
            columnDef: 'leadVolumeMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Total Leads',
            columnDef: 'leadVolumePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Leads YOY',
            columnDef: 'leadVolumeYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Response Rate',
            columnDef: 'responseRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Response Rate',
            columnDef: 'responseRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Response Rate MOM',
            columnDef: 'responseRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Response Rate',
            columnDef: 'responseRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Response Rate YOY',
            columnDef: 'responseRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Response Rate < 40 min',
            columnDef: 'responseRate40',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Response Rate < 40 min',
            columnDef: 'responseRate40PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 40 min Hr MOM',
            columnDef: 'responseRate40MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Response Rate < 40 min',
            columnDef: 'responseRate40PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Response Rate < 40 min YOY',
            columnDef: 'responseRate40YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Average Response Time (Within 48 Hrs)',
            columnDef: 'averageResponseTime48Hour',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Average Response (Within 48 Hrs) Time',
            columnDef: 'averageResponseTime48HourPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Average Response Time (Within 48 Hrs) MOM',
            columnDef: 'averageResponseTime48HourMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Average Response Time (Within 48 Hrs)',
            columnDef: 'averageResponseTime48HourPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Average Response Time (Within 48 Hrs) YOY',
            columnDef: 'averageResponseTime48HourYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Overall Average Response Time',
            columnDef: 'averageResponseTime',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Overall Average Response Time',
            columnDef: 'averageResponseTimePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Overall Average Response Time MOM',
            columnDef: 'averageResponseTimeMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Overall Average Response Time',
            columnDef: 'averageResponseTimePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
        },
        {
            show: false,
            header: 'Overall Average Response Time YOY',
            columnDef: 'averageResponseTimeYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Lead Sales',
            columnDef: 'bestMatchSales',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Lead Sales',
            columnDef: 'bestMatchSalesPreviousMTD',
            isMom: true,
            isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lead Sales MOM',
            columnDef: 'bestMatchSalesMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Lead Sales',
            columnDef: 'bestMatchSalesPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lead Sales YOY',
            columnDef: 'bestMatchSalesYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Lost Sales',
            columnDef: 'lostDealerSales',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Lost Sales',
            columnDef: 'lostDealerSalesPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lost Sales MOM',
            columnDef: 'lostDealerSalesMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Lost Sales',
            columnDef: 'lostDealerSalesPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Lost Sales YOY',
            columnDef: 'lostDealerSalesYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Close Rate',
            columnDef: 'bestMatchCloseRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Close Rate',
            columnDef: 'bestMatchCloseRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Close Rate MOM',
            columnDef: 'bestMatchCloseRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Close Rate',
            columnDef: 'bestMatchCloseRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Close Rate YOY',
            columnDef: 'bestMatchCloseRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: '30 Day Close Rate',
            columnDef: 'bestMatchCloseRate30',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month 30 Day Close Rate',
            columnDef: 'bestMatchCloseRate30PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: '30 Day Close Rate MOM',
            columnDef: 'bestMatchCloseRate30MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year 30 Day Close Rate',
            columnDef: 'bestMatchCloseRate30PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: '30 Day Close Rate YOY',
            columnDef: 'bestMatchCloseRate30YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: true,
            header: '60 Day Close Rate',
            columnDef: 'bestMatchCloseRate60',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month 60 Day Close Rate',
            columnDef: 'bestMatchCloseRate60PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: '60 Day Close Rate MOM',
            columnDef: 'bestMatchCloseRate60MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year 60 Day Close Rate',
            columnDef: 'bestMatchCloseRate60PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: '60 Day Close Rate YOY',
            columnDef: 'bestMatchCloseRate60YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: true,
            header: '90 Day Close Rate',
            columnDef: 'bestMatchCloseRate90',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month 90 Day Close Rate',
            columnDef: 'bestMatchCloseRate90PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: '90 Day Close Rate MOM',
            columnDef: 'bestMatchCloseRate90MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year 90 Day Close Rate',
            columnDef: 'bestMatchCloseRate90PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: '90 Day Close Rate YOY',
            columnDef: 'bestMatchCloseRate90YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: true,
            header: '120 Day Close Rate',
            columnDef: 'bestMatchCloseRate120',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month 120 Day Close Rate',
            columnDef: 'bestMatchCloseRate120PreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: '120 Day Close Rate MOM',
            columnDef: 'bestMatchCloseRate120MOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year 120 Day Close Rate',
            columnDef: 'bestMatchCloseRate120PreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: '120 Day Close Rate YOY',
            columnDef: 'bestMatchCloseRate120YOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: true,
            header: 'Appointment Rate',
            columnDef: 'appointmentRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Prev. Month Appointment Rate',
            columnDef: 'appointmentRatePreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
        },
        {
            show: false,
            header: 'Appointment Rate MOM',
            columnDef: 'appointmentRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Appointment Rate',
            columnDef: 'appointmentRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Appointment Rate YOY',
            columnDef: 'appointmentRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
    ];

    public trendTableMetrics: INameValuePair[] = [
        {name: 'Total Leads', value: 'leadVolume'},
        {name: 'Response Rate', value: 'responseRate'},
        {name: 'Response Rate < 40 min', value: 'responseRate40'},
        {name: 'Avg. Response Time (Within 48 Hr)', value: 'averageResponseTime48Hour'},
        {name: 'Overall Avg. Response Time', value: 'averageResponseTime'},
        {name: 'Lead Sales', value: 'bestMatchSales'},
        {name: 'Close Rate', value: 'bestMatchCloseRate'},
        {name: '30 Day Close Rate', value: 'bestMatchCloseRate30'},
        {name: '60 Day Close Rate', value: 'bestMatchCloseRate60'},
        {name: '90 Day Close Rate', value: 'bestMatchCloseRate90'},
        {name: '120 Day Close Rate', value: 'bestMatchCloseRate120'},
        {name: 'Appointment Rate', value: 'appointmentRate'},
    ];
}
