export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Mercedes-Benz',
  hostName: 'staging.analytics.mbusaleads.com',
  environmentName: 'staging',
  appName: 'Mercedes-Benz Analytics',
  printUser: 'print@shiftdigital.com',
  printPassword: 'ShiftDigital#1',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '9562e76d-0d18-4bdf-9a41-ad3d68408d99'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#000', '#737373', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://mbusa-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://mbusa-analytics-api-staging.azurewebsites.net/token',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/mercedes-us/favicon.ico',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false
};
