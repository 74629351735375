<div class="card-header">
    <div class="pull-left">
      <h5><i [ngClass]="iconClass" class="fa fa-primary pad-right"></i><b>{{title}}</b></h5>
    </div>
    <div class="pull-right">
      <!-- <ng-container *ngIf="!!metricDisplayModes">
        <mat-form-field [floatLabel]="'never'">
            <mat-select #tableOptions [(value)]="selectedTableOptions" *ngIf="tableOptions$ | async"  placeholder="Default" multiple (selectionChange)="updateTableScenarios($event)">
              <mat-option *ngFor="let displayMode of metricDisplayModes"
                [disabled]="(isTrend && displayMode !== 'Trend') || (isComp && displayMode === 'Trend')"
                (click)="tableOptions.close()"
                [value]="displayMode">
                {{displayMode}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        &nbsp;
        &nbsp;
      </ng-container> -->
      <ng-container *ngIf="!!helpTextKey || !!exportName">
        <button mat-button class="card-options-button" [matMenuTriggerFor]="menu"><i class="fa fa-primary fa-ellipsis-v" style="font-size: 1.4em;"></i></button>
        <mat-menu #menu="matMenu">
          <ng-container *ngIf="!!exportName">
            <button mat-menu-item style="outline: none;" (click)="printExcel(exportName, false)">Excel</button>
            <button mat-menu-item style="outline: none;"  (click)="printExcel(exportName, true)">Excel - {{'Export All' | translate: locale}}</button>
          </ng-container>
          <button mat-menu-item style="outline: none;" (click)="openHelpText(helpTextKey, title)">{{'Help' | translate: locale}}</button>
        </mat-menu>
      </ng-container>
    </div>
  </div>