import {
    IDefaultTableColumnConfig,
    IWebsiteSummaryConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig,
    INameValuePair} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteSummaryConfig implements IWebsiteSummaryConfig {
    reportTitle = 'Website Summary';
    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.websiteSummaryHelp,
        helpTextTitle: 'Website Summary',
        exportName: 'Website Summary - Summary Data',
        metricDisplayModes: ['MOM', 'YOY', 'Trend']
    };

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.websiteMetrics.uniqueVisits.metricId,
                displayName: Enums.websiteMetrics.uniqueVisits.name,
                propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.totalVisits.metricId,
                displayName: Enums.websiteMetrics.totalVisits.name,
                propertyName: Enums.websiteMetrics.totalVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.pageViews.metricId,
                displayName: Enums.websiteMetrics.pageViews.name,
                propertyName: Enums.websiteMetrics.pageViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.pageViewsPerVisit.metricId,
                displayName: Enums.websiteMetrics.pageViewsPerVisit.name,
                propertyName: Enums.websiteMetrics.pageViewsPerVisit.nameKey,
                metricFormatKey: constants.formatKeys.roundToHundreth
            },
            {
                id: Enums.websiteMetrics.formConversionRate.metricId,
                displayName: Enums.websiteMetrics.formConversionRate.name,
                propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.websiteMetrics.formSubmissions.metricId,
                displayName: Enums.websiteMetrics.formSubmissions.name,
                propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.avgTimeOnSite.metricId,
                displayName: Enums.websiteMetrics.avgTimeOnSite.name,
                propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.phoneCalls.metricId,
                displayName: Enums.websiteMetrics.phoneCalls.name,
                propertyName: Enums.websiteMetrics.phoneCalls.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.clickToCalls.metricId,
                displayName: Enums.websiteMetrics.clickToCalls.name,
                propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.newInventoryDetailViews.name,
                propertyName: Enums.websiteMetrics.newInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.usedInventoryDetailViews.name,
                propertyName: Enums.websiteMetrics.usedInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
                displayName: Enums.websiteMetrics.cpoInventoryDetailViews.name,
                propertyName: Enums.websiteMetrics.cpoInventoryDetailViews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            }
        ],
        defaultMetricOneId: Enums.websiteMetrics.totalVisits.metricId,
        defaultMetricTwoId: Enums.websiteMetrics.pageViews.metricId,
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    };
    public trendTableMetrics: INameValuePair[] = [
      {name: 'Unique Visitors', value: 'uniqueVisits'},
      {name: 'Website Visits', value: 'websiteVisits'},
      {name: 'Page Views', value: 'pageViews'},
      {name: 'Page Views per Visit', value: 'pageViewsPerVisit'},
      {name: 'Form Conversion Rate', value: 'formConversionRate'},
      {name: 'Form Submissions', value: 'formSubmissions'},
      {name: 'Avg. Time on Site', value: 'averageTimeOnSite'},
      {name: 'Phone Calls', value: 'phoneCalls'},
      {name: 'Click to Call', value: 'clickToCalls'},
      {name: 'New Inventory Detail Views', value: 'newVdpViews'},
      {name: 'Used Inventory Detail Views', value: 'usedVdpViews'},
      {name: 'CPO Inventory Detail Views', value: 'cpoVdpViews'},
    ];
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
          },
          {
              show: false,
              print: true,
              header: 'Dealer Name',
              columnDef: 'displayName',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.entityDisplayName,
          },
          {
            show: true,
            header: 'Website Visits',
            columnDef: 'totalVisits',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Website Visits',
              columnDef: 'totalVisitsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Website Visits MOM',
              columnDef: 'totalVisitsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Website Visits',
              columnDef: 'totalVisitsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Website Visits YOY',
              columnDef: 'totalVisitsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'Unique Visitors',
              columnDef: 'uniqueVisits',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Unique Visitors',
              columnDef: 'uniqueVisitsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Unique Visitors MOM',
              columnDef: 'uniqueVisitsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Unique Visitors',
              columnDef: 'uniqueVisitsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Unique Visitors YOY',
              columnDef: 'uniqueVisitsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'Total Page Views',
              columnDef: 'pageViews',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Total Page Views',
              columnDef: 'pageViewsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Total Page Views MOM',
              columnDef: 'pageViewsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Total Page Views',
              columnDef: 'pageViewsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Total Page Views YOY',
              columnDef: 'pageViewsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'Page Views Per Visit',
              columnDef: 'pageViewsPerVisit',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Page Views Per Visit',
              columnDef: 'pageViewsPerVisitPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
          },
          {
              show: false,
              header: 'Page Views Per Visit MOM',
              columnDef: 'pageViewsPerVisitMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Page Views Per Visit',
              columnDef: 'pageViewsPerVisitPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
          },
          {
              show: false,
              header: 'Page Views Per Visit YOY',
              columnDef: 'pageViewsPerVisitYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Time On Site',
            columnDef: 'averageTimeOnSite',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
          },
          {
              show: false,
              header: 'Time On Site MOM',
              columnDef: 'averageTimeOnSiteMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Prev. Month Time On Site',
              columnDef: 'averageTimeOnSitePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
          },
          {
              show: false,
              header: 'Time On Site YOY',
              columnDef: 'averageTimeOnSiteYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Prev. Year Time On Site',
              columnDef: 'averageTimeOnSitePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
          },
          {
            show: true,
            header: 'Engagements',
            columnDef: 'engagements',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Engagements',
              columnDef: 'engagementsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Engagements MOM',
              columnDef: 'engagementsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Engagements',
              columnDef: 'engagementsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Engagements YOY',
              columnDef: 'engagementsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Engagement Rate',
            columnDef: 'engagementRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Engagement Rate',
              columnDef: 'engagementRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Engagement Rate MOM',
              columnDef: 'engagementRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Engagement Rate',
              columnDef: 'engagementRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Engagement Rate YOY',
              columnDef: 'engagementRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Actions',
            columnDef: 'actions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Actions',
              columnDef: 'actionsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Actions MOM',
              columnDef: 'actionsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Actions',
              columnDef: 'actionsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Actions YOY',
              columnDef: 'actionsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Action Rate',
            columnDef: 'actionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Action Rate',
              columnDef: 'actionRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Action Rate MOM',
              columnDef: 'actionRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Action Rate',
              columnDef: 'actionRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Action Rate YOY',
              columnDef: 'actionRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Form Submissions',
            columnDef: 'formSubmissions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Form Submissions',
              columnDef: 'formSubmissionsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Form Submissions MOM',
              columnDef: 'formSubmissionsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Form Submissions',
              columnDef: 'formSubmissionsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Form Submissions YOY',
              columnDef: 'formSubmissionsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'Form Conversion Rate',
              columnDef: 'formConversionRate',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Form Conversion Rate',
              columnDef: 'formConversionRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Form Conversion Rate MOM',
              columnDef: 'formConversionRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Form Conversion Rate',
              columnDef: 'formConversionRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Form Conversion Rate YOY',
              columnDef: 'formConversionRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'Phone Calls',
              columnDef: 'phoneCalls',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Phone Calls',
              columnDef: 'phoneCallsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Phone Calls MOM',
              columnDef: 'phoneCallsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Phone Calls',
              columnDef: 'phoneCallsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Phone Calls YOY',
              columnDef: 'phoneCallsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Phone Conversion Rate',
            columnDef: 'phoneConversionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Phone Conversion Rate',
              columnDef: 'phoneConversionRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Phone Conversion Rate MOM',
              columnDef: 'phoneConversionRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Phone Conversion Rate',
              columnDef: 'phoneConversionRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Phone Conversion Rate YOY',
              columnDef: 'phoneConversionRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Total Contacts',
            columnDef: 'totalContacts',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Total Contacts',
              columnDef: 'totalContactsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Total Contacts MOM',
              columnDef: 'totalContactsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Total Contacts',
              columnDef: 'totalContactsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Total Contacts YOY',
              columnDef: 'totalContactsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Total Contact Rate',
            columnDef: 'totalContactRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Total Contact Rate',
              columnDef: 'totalContactRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Total Contact Rate MOM',
              columnDef: 'totalContactRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Total Contact Rate',
              columnDef: 'totalContactRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Total Contact Rate YOY',
              columnDef: 'totalContactRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'Click to Call',
              columnDef: 'clickToCalls',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Click to Call',
              columnDef: 'clickToCallsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Click to Call MOM',
              columnDef: 'clickToCallsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Click to Call',
              columnDef: 'clickToCallsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Click to Call YOY',
              columnDef: 'clickToCallsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
            show: true,
            header: 'Bounce Rate',
            columnDef: 'bounceRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Bounce Rate',
              columnDef: 'bounceRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Bounce Rate MOM',
              columnDef: 'bounceRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Bounce Rate',
              columnDef: 'bounceRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Bounce Rate YOY',
              columnDef: 'bounceRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'New Inventory Detail Views',
              columnDef: 'newInventoryDetailViews',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month New Inventory Detail Views',
              columnDef: 'newInventoryDetailViewsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'New Inventory Detail Views MOM',
              columnDef: 'newInventoryDetailViewsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year New Inventory Detail Views',
              columnDef: 'newInventoryDetailViewsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'New Inventory Detail Views YOY',
              columnDef: 'newInventoryDetailViewsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'Used Inventory Detail Views',
              columnDef: 'usedInventoryDetailViews',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Used Inventory Detail Views',
              columnDef: 'usedInventoryDetailViewsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Used Inventory Detail Views MOM',
              columnDef: 'usedInventoryDetailViewsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Used Inventory Detail Views',
              columnDef: 'usedInventoryDetailViewsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Used Inventory Detail Views YOY',
              columnDef: 'usedInventoryDetailViewsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: true,
              header: 'CPO Inventory Detail Views',
              columnDef: 'cpoInventoryDetailViews',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month CPO Inventory Detail Views',
              columnDef: 'cpoInventoryDetailViewsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'CPO Inventory Detail Views MOM',
              columnDef: 'cpoInventoryDetailViewsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year CPO Inventory Detail Views',
              columnDef: 'cpoInventoryDetailViewsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'CPO Inventory Detail Views YOY',
              columnDef: 'cpoInventoryDetailViewsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
    ];
}
